import React from 'react';
import './CourseCard.scss';
import { classes } from '../../services/utils';
import Tag from '../AppTag/Tag';

import czechLanguageImage from '../../assets/images/icons/ic-czech-language-primary.svg';
import mathImage from '../../assets/images/icons/ic-math-primary.svg';
import englishImage from '../../assets/images/icons/ic-english-primary.svg';
import { formateNumber } from '../../services/utils';

interface ICourseCard {
    image?: string;
    title: string;
    onClick?: () => void;
    onRemove?: () => void;
    tag?: string;
    color?: 'primary' | 'secondary';
    className?: string;
    removeButton?: boolean;
    disabled?: boolean;
    price?: number;
}

const baseImages: { [key: string]: string } = {
    matematika: mathImage,
    čeština: czechLanguageImage,
    angličtina: englishImage,
};

const CourseCard: React.FC<ICourseCard> = (props) => {
    const { tag, color = 'primary', className, image, onRemove, onClick, removeButton = true, disabled, title, price } = props;
    return (
        <div
            className={classes(`course-card is-${color}`, {
                [className || '']: !!className,
                'is-link': !!onClick,
                'is-disabled': !!disabled,
            })}
            onClick={onClick}
        >
            {removeButton && !disabled && (
                <button
                    type="button"
                    onClick={onRemove}
                    className="course-card-button icon-bin-gray"
                    title="Odebrat kurz"
                >
                    Odebrat
                </button>
            )}
            {disabled && (
                <span className="course-card-button icon-lock-gray" title="Nedostupný kurz">
                    Zamčeno
                </span>
            )}
            {tag && <Tag color={color}>{tag}</Tag>}
            <div className="course-card-price">{price && `${formateNumber(price, 0)} Kč`}</div>
            <div className="course-card-image" style={{ backgroundImage: `url('${baseImages[title] || image}')` }}></div>
            <div className="course-card-content">{props.children}</div>
        </div>
    );
};

export default CourseCard;
